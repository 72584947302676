<template>
  <div class="edit-view">
    <page-title>
      <el-button @click="$router.go(-1)">取消</el-button>
      <el-button @click="del"
                 :loading="$store.state.fetchingData"
                 type="danger"
                 v-if="formData.id && formData.id !== 1">删除
      </el-button>
      <el-button @click="onSave"
                 :loading="$store.state.fetchingData"
                 type="primary">保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
      <div class="edit-view__content-section">
        <el-form :model="formData"
                 :rules="rules"
                 ref="form"
                 label-width="80px"
                 label-position="right"
                 style="max-width: 500px">
          <el-form-item label="项目"
                        prop="minterProjectId">
            <project-select v-model="formData.minterProjectId">
            </project-select>
          </el-form-item>
          <el-form-item prop="avatar"
                        label="头像">
            <crop-upload v-model="formData.avatar" @input=""></crop-upload>
          </el-form-item>
          <el-form-item prop="username"
                        label="用户名">
            <el-input v-model="formData.username"></el-input>
            <div class="gen"
                 @dblclick="gen"></div>
          </el-form-item>
          <el-form-item prop="nickname"
                        label="昵称">
            <el-input v-model="formData.nickname"></el-input>
          </el-form-item>
          <el-form-item v-if="formData.id"
                        label="密码">
            <el-button type="primary"
                       plain
                       @click="resetPassword"
                       size="mini">重置 </el-button>
          </el-form-item>
          <el-form-item v-else
                        prop="password"
                        label="密码">
            <el-input v-model="formData.password"></el-input>
          </el-form-item>
          <el-form-item prop="phone"
                        label="手机">
            <el-input v-model="formData.phone"
                      :disabled="!!formData.id"></el-input>
          </el-form-item>
          <el-form-item prop="intro"
                        label="简介">
            <el-input v-model="formData.intro"
                      placeholder="300字以内"
                      maxlength="300"
                      type="textarea"
                      autosize></el-input>
          </el-form-item>
          <!-- <el-form-item prop="authorities" label="角色">
                        <el-select
                            v-model="formData.authorities"
                            multiple
                            placeholder="请选择"
                            value-key="name"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in authorities"
                                :key="item.name"
                                :label="item.description"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
          <el-form-item>
            <el-button @click="onSave"
                       :loading="saving"
                       type="primary">保存</el-button>
            <el-button @click="del"
                       :disabled="saving"
                       type="danger"
                       v-if="formData.id && formData.id !== 1">删除
            </el-button>
            <el-button @click="$router.go(-1)"
                       :disabled="saving">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import randomstring from 'randomstring';
import faker from 'faker';
faker.locale = 'zh_CN';
console.log(faker);
export default {
    created() {
        if (this.$route.query.id) {
            this.$http
                .get(`/user/get/${this.$route.query.id}`)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.$http
            .get('/authority/all')
            .then(res => {
                this.authorities = res;
            })
            .catch(e => {
                console.log(e);
            });
    },
    data() {
        return {
            saving: false,
            formData: {
                minterProjectId: this.$store.state.projectId,
                username:
                    '9th_' +
                    randomstring.generate({
                        length: 6,
                        charset: 'alphabetic',
                        capitalization: 'lowercase'
                    }),
                avatar:
                    parseInt(Math.random() * 10) % 2 === 1
                        ? 'https://awesomeadmin.oss-cn-hangzhou.aliyuncs.com/image/avatar_male.png'
                        : 'https://awesomeadmin.oss-cn-hangzhou.aliyuncs.com/image/avatar_female.png',
                authorities: [
                    { name: 'ROLE_MINTER', description: '铸造者' },
                    { name: 'ROLE_USER', description: '普通用户' }
                ]
            },
            rules: {
                avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
                username: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
                nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                phone: [
                    // { required: true, message: '请输入密码', trigger: 'blur' },
                    {
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入正确的手机号',
                        trigger: 'blur'
                    }
                ],
                authorities: [{ required: true, message: '请选择角色', trigger: 'blur' }],
                intro: [{ required: true, message: '请输入简介', trigger: 'blur' }],
                minterProjectId: [{ required: true, message: '请选择项目' }]
            },
            authorities: [
                { name: 'ROLE_MINTER', description: '铸造者' },
                { name: 'ROLE_USER', description: '普通用户' }
            ]
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            this.saving = true;
            this.$http
                .post(this.formData.id ? '/user/save' : '/user/create', this.formData, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.formData = res;
                    this.$router
                        .replace({
                            query: {
                                id: res.id
                            }
                        })
                        .catch(_ => {});
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        del() {
            this.$confirm('确认删除吗？', '提示', { type: 'warning' })
                .then(() => {
                    return this.$http.post(`/user/del/${this.formData.id}`);
                })
                .then(res => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if ('cancel' !== e) {
                        this.$message.error(e.error || '删除失败');
                    }
                });
        },
        resetPassword() {
            this.$prompt('请输入新密码', '重置密码', { inputType: 'password' })
                .then(res => {
                    console.log(res);
                    if (res.value) {
                        this.$alert('确定重置密码？', '提示', {
                            showCancelButton: true
                        })
                            .then(() => {
                                return this.$http.post('/user/setPasswordAdmin', {
                                    userId: this.formData.id,
                                    password: res.value
                                });
                            })
                            .then(res => {
                                this.$message.success('密码重置成功');
                            })
                            .catch(() => {
                                this.$message.error(res.error || '重置密码失败');
                            });
                    }
                })
                .catch(() => {});
        },
        gen() {
            const card = faker.helpers.createCard();
            this.formData.username = card.username;
            this.formData.nickname = card.name;
            this.formData.phone = card.phone;
            this.$message('ok');
            console.log(card);
        }
    }
};
</script>
<style lang="less" scoped>
.gen {
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 32px;
}
</style>
